<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Create new article
			</div>
		</div>

		<div>
			<article-edit
				:cancelRoute="getCancelRoute"
				:isCreate="true"
			/>
		</div>
	</div>
</template>

<script>
	import ArticleEdit  from '@/components/knowledgebase/edit/ArticleEdit';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Create new article'
		},
		components: {
			ArticleEdit
		},
		data: () => ({}),
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/articles';
			}
		},
		created () {},
		methods: {}
	};

</script>
